.css-zddlty-MuiButtonBase-root-MuiButton-root {
  text-transform: none !important;
  color: #ffd100 !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  font-family: "Kanit" !important;
  background: none !important;
}
.css-fvc8ir-MuiBadge-badge {
  background: #ffd100;
  color: #fff;
  font-family: "Kanit";
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  color: #ffd100 !important;
  background: #fff !important;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border-radius: 8px !important;
}
